<template>
  <v-form autocomplete="off" aria-autocomplete="none" @submit.prevent="submit">
    <v-card flat outlined tile>
      <v-toolbar flat dense color="bg_section" class="">
        <v-toolbar-title class="subtitle-2">
          <v-icon left>mdi-magnify</v-icon>
          Ricerca
        </v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text class="mb-0 pb-0">
        <slot />
        <v-expand-transition>
          <div v-if="extra"><slot name="extra"/></div>
        </v-expand-transition>
      </v-card-text>

      <v-card-actions class="ml-2">
        <v-btn
          class="white--text px-3"
          color="green darken-1"
          depressed
          tile
          type="submit"
        >
          <v-icon left>mdi-magnify</v-icon>
          Ricerca
        </v-btn>
        <v-btn text small tile @click="reset"> Reset </v-btn>

        <v-btn v-if="$slots['extra']" text small @click="extra = !extra">
          <v-icon left>
            {{ extra ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
          Tutte le opzioni
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: 'BaseSectionSearch',
  data: () => ({
    extra: false,
  }),
  methods: {
    submit() {
      this.$emit('submit')
    },
    reset() {
      this.$emit('reset')
    },
  },
}
</script>

<style scoped></style>
